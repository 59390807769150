import { Popover } from 'antd';
import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import styles from './index.less';
import type { MatchedGmsStyleList } from './interface';

interface Props{
  goods: MatchedGmsStyleList;
  showPopover?: boolean;
  list?: MatchedGmsStyleList[];
}

const PosGoodsInfo = ({ goods, list = [], showPopover = false }: Props) => {
  const content = list?.map((item) => {
    return (
      <PosGoodsInfo
        goods={item}
        key={item.gmsStyleId}
      />
    );
  });

  return (
    <div className={styles.info}>
      <ImgFormatter
        height={56}
        value={goods?.mainPicUrl || noPic}
        width={56}
      />
      <div className={styles.infoRightPart}>
        <div
          className={`${styles.goodsName} ${styles.link}`}
          title={goods?.styleName}
        >
          <span
            className={styles.goodsNameText}
            onClick={() => {
              window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goods?.posGoodsId}`);
            }}
          >
            {goods?.styleName}
          </span>
          <i
            className="icon-sq"
            onClick={() => {
              window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goods?.posGoodsId}`);
            }}
          />
          {showPopover && list?.length > 0 && (
            <Popover
              content={content}
              title="关联衫海精商品"
            >
              <span className={styles.more}>
                共
                {list?.length}
                款
              </span>
            </Popover>
          )}
        </div>
        <div className={styles.goodsName}>
          货号：
          {goods?.styleNo}
        </div>
        <div
          className={styles.goodsName}
          title={goods?.vendorShopName}
        >
          档口：
          {goods?.vendorShopName}
        </div>
        <div className={goods?.status === 3 ? `${styles.goodsStatus} ${styles.greenTags}` : `${styles.goodsStatus} ${styles.redTags}`}>
          {goods?.status === 3 ? '档口在售' : '仓库中'}
        </div>
      </div>
    </div>
  );
};

export { PosGoodsInfo };
