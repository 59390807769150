
import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { EgGridModel, ImgFormatter } from 'egenie-utils';
import { action, autorun, observable } from 'mobx';
import React from 'react';
import noPic from '../../../assets/images/noPic.png';
import { api } from '../../../utils';
import styles from './index.less';
import type { Size, SKU, VendorGoods } from './interface';

export default class SingleMatchModalStore {
  constructor(props) {
    this.parent = props?.parent;

    autorun(() => {
      const sizeList = this.skuList?.find((item) => item.color === this.currentColor)?.skuList;
      this.sizeList = sizeList;
      if (!sizeList) {
        return;
      }
      const sku = sizeList.find((item) => item.size === this.currentSize);
      if (sku) {
        this.goodsSkuNo = sku.goodsSkuNo;
        this.posGoodsSkuId = sku.posGoodsSkuId;
      }
    });
  }

  @observable public parent = null;

  @observable public visible = false;

  @observable public loading = false;

  @observable public vendorFormRef = null;

  @observable public gmsGoodsId = null;

  @observable public gmsGoodsSkuId = null;

  @observable public posGoodsId = null;

  @observable public skuList: SKU[] = [];

  @observable public currentColor = '';

  @observable public currentSize = '';

  @observable public sizeList: Size[] = [];

  @observable public goodsSkuNo = '';

  @observable public posGoodsSkuId = null;

  @action public setVendorRef = (form: FormInstance): void => {
    this.vendorFormRef = form;
  };

  @action public onShow = (gmsGoodsId: number, gmsGoodsSkuId: number): void => {
    this.gmsGoodsId = gmsGoodsId;
    this.gmsGoodsSkuId = gmsGoodsSkuId;
    this.visible = true;
  };

  @action public onClose = (): void => {
    this.visible = false;
    this.posGoodsId = null;
    this.skuList = [];
    this.gridModel.resetAll();
    this.gridModel.rows = [];
    this.vendorFormRef.resetFields();
  };

  // 搜索档口款式
  @action public queryVendorGoods = async(page?: number, pageSize?: number): Promise<void> => {
    const values = this.vendorFormRef ? await this.vendorFormRef.validateFields() : {};
    if (!values.goodsName && !values.goodsNo && !values.vendorId) {
      message.warning('请先填写查询条件');
      return;
    }
    const res: PaginationData<VendorGoods> = await request({
      url: api.getVendorGoodsList,
      method: 'post',
      data: {
        page: page || 1,
        pageSize: pageSize || 50,
        ...values,
      },
    });
    this.gridModel.rows = res.data.list;
    this.gridModel.current = res.data.page;
    this.gridModel.pageSize = res.data.pageSize;
    this.gridModel.total = res.data.totalCount;
  };

  @action public queryVendorGoodsSku = async(posGoodsId: number): Promise<void> => {
    this.posGoodsId = posGoodsId;
    this.currentColor = '';
    this.currentSize = '';
    this.goodsSkuNo = '';
    this.posGoodsSkuId = null;
    const res: BaseData<SKU[]> = await request({
      url: api.listPosGoodsSku,
      method: 'POST',
      data: { posGoodsId },
    });
    this.skuList = res.data;
  };

  @action public onClickColor = (color: string): void => {
    this.currentColor = color;
  };

  @action public onClickSize = (size: string): void => {
    this.currentSize = size;
  };

  @action public onSubmit = async(): Promise<void> => {
    if (!this.posGoodsSkuId) {
      message.warning('请先关联!');
      return;
    }
    try {
      this.loading = true;
      await request({
        url: api.skuMatch,
        method: 'POST',
        data: {
          gmsGoodsId: this.gmsGoodsId,
          gmsGoodsSkuId: this.gmsGoodsSkuId,
          posGoodsId: this.posGoodsId,
          posGoodsSkuId: this.posGoodsSkuId,
        },
      });
      message.success('关联成功!');
      setTimeout(() => {
        this.onClose();
        this.parent && this.parent.skuMatchCallBack();
      }, 500);
    } finally {
      this.loading = false;
    }
  };

  @observable public gridModel = new EgGridModel(
    {
      columns: [
        {
          key: 'goods',
          name: '图片',
          formatter: ({ row }) => {
            return (
              <div className={styles.alignCenter}>
                {
                  row.mainPicUrl ? (
                    <ImgFormatter
                      height={56}
                      value={row.mainPicUrl}
                      width={56}
                    />
                  ) : (
                    <img
                      className={styles.goodsPic}
                      src={noPic}
                    />
                  )
                }
              </div>
            );
          },
        },
        {
          key: 'vendorShopName',
          name: '档口名称',
        },
        {
          key: 'goodsName',
          name: '衫海精商品名称',
        },
        {
          key: 'goodsNo',
          name: '衫海精款式货号',
        },
        {
          key: 'costPriceStr',
          name: '拿货价',
          formatter: ({ row }) => {
            return row.costPriceStr ? (
              <span>
                {row.costPriceStr}
              </span>
            ) : (
              <span>
                -
              </span>
            );
          },
        },

      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'goodsId',
      sortByLocal: false,
      showCheckBox: false,
      showGridOrderNo: false,
      showSelectedTotal: false,
      showReset: false,
      showEmpty: false,
      showNoSearchEmpty: false,
      showNormalEmpty: false,
      rowHeight: 74,
      api: {
        onQuery: this.queryVendorGoods,
        onPageChange: this.queryVendorGoods,
        onShowSizeChange: this.queryVendorGoods,
        onRowClick: (posGoodsId) => {
          if (!posGoodsId) {
            return;
          }
          this.queryVendorGoodsSku(Number(posGoodsId));
        },
      },
    });
}
