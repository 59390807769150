import { Drawer, Layout } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import noPic from '../../../assets/images/noPic.png';
import { SingleMatchModal } from '../singleMatchModal/index';
import styles from './index.less';
import type SkuMatchDetailStore from './store';

const SkuMatchDetails = observer((props: { store: SkuMatchDetailStore; }) => {
  const {
    visible,
    gmsGoods,
    gridModel,
    singleMatchModalStore,
    onClose,
  } = props.store;

  return (
    <Drawer
      onClose={onClose}
      open={visible}
      title="SKU关联详情"
      width={1100}
    >
      <Layout className={styles.skuMatchDetail}>
        <Layout.Content>
          <div className={styles.header}>
            店铺商品
          </div>
          <div className={styles.gmsGoods}>
            <img
              className={styles.pic}
              src={gmsGoods.mainPicUrl || noPic}
            />
            <div className={styles.infoRightPart}>
              <div
                className={styles.goodsName}
                title={gmsGoods.goodsName}
              >
                {gmsGoods.goodsName}
              </div>
              <div
                className={styles.platformCode}
                title={gmsGoods.platformCode}
              >
                店铺商品编码：
                {gmsGoods.platformCode}
              </div>
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <EgGrid store={gridModel}/>
          </div>
        </Layout.Content>
      </Layout>
      <SingleMatchModal store={singleMatchModalStore}/>
    </Drawer>
  );
});

export { SkuMatchDetails };
