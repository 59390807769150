import { Layout, Modal } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { VendorGoodsForm } from '../../components';
import styles from './index.less';
import type { Size, SKU } from './interface';
import type SingleMatchModalStore from './store';

const SingleMatchModal = observer((props: { store: SingleMatchModalStore; }) => {
  const {
    visible,
    loading,
    gridModel,
    skuList,
    sizeList,
    goodsSkuNo,
    currentColor,
    posGoodsSkuId,
    setVendorRef,
    queryVendorGoods,
    onClose,
    onSubmit,
    onClickColor,
    onClickSize,
  } = props.store;
  return (
    <Modal
      confirmLoading={loading}
      onCancel={onClose}
      onOk={onSubmit}
      open={visible}
      title="关联SKU"
      width={870}
    >
      <Layout className={styles.singleMatchModal}>
        <Layout.Content>
          <div>
            <VendorGoodsForm
              inputStyle={{ width: 180 }}
              onSearch={() => queryVendorGoods()}
              setVendorRef={setVendorRef}
            />
          </div>
          <div className={styles.tableWrapper}>
            <EgGrid store={gridModel}/>
          </div>
          <div
            className={styles.header}
            style={{ marginTop: 14 }}
          >
            选择SKU
          </div>
          <div className={styles.skuInfo}>
            <label>
              颜色：
            </label>
            {skuList?.map((item: SKU) => {
              return (
                <span
                  className={item.color === currentColor ? `${styles.skuItem} ${styles.selectedItem}` : styles.skuItem}
                  key={item.color}
                  onClick={() => onClickColor(item.color)}
                >
                  {item.color}
                </span>
              );
            })}
          </div>
          <div className={styles.skuInfo}>
            <label>
              尺码：
            </label>
            {sizeList?.map((item: Size) => {
              return (
                <span
                  className={item.posGoodsSkuId === posGoodsSkuId ? `${styles.skuItem} ${styles.selectedItem}` : styles.skuItem}
                  key={item.posGoodsSkuId}
                  onClick={() => onClickSize(item.size)}
                >
                  {item.size}
                </span>
              );
            })}
          </div>
          <div>
            <label>
              SKU编码：
              {goodsSkuNo}
            </label>
          </div>
        </Layout.Content>
      </Layout>
    </Modal>
  );
});
export { SingleMatchModal };
