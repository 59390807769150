import { Button, message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { EgGridModel, ImgFormatter } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import noPic from '../../../assets/images/noPic.png';
import { api } from '../../../utils';
import SingleMatchModalStore from '../singleMatchModal/store';
import styles from './index.less';
import type { Detail, GmsGoods } from './interface';

export default class SkuMatchDetailsStore {
  @observable public visible = false;

  @observable public gmsGoodsId = null;

  @observable public gmsGoods: Partial<GmsGoods> = {};

  @observable public singleMatchModalStore = new SingleMatchModalStore({ parent: this });

  @action public onShow = (gmsGoodsId: number, gmsGoods: GmsGoods): void => {
    this.gmsGoodsId = gmsGoodsId;
    this.gmsGoods = gmsGoods;
    this.handleQuery();
    this.visible = true;
  };

  @action public onClose = (): void => {
    this.visible = false;
    this.gmsGoodsId = null;
    this.gridModel.rows = [];
  };

  @action public handleQuery = async(): Promise<void> => {
    const res: BaseData<Detail[]> = await request({
      url: api.queryMatchDetails,
      method: 'POST',
      data: { gmsGoodsId: this.gmsGoodsId },
    });
    this.gridModel.rows = res.data;
  };

  @action public skuMatchCallBack = (): void => {
    this.handleQuery();
  };

  @action public cancelSkuMatch = async(gmsGoodsSkuId: number): Promise<void> => {
    await request({
      url: api.cancelSkuMatch,
      method: 'POST',
      data: { gmsGoodsSkuId },
    });
    message.success('取消关联成功');
    this.handleQuery();
  };

  @observable public gridModel = new EgGridModel(
    {
      columns: [
        {
          key: 'tags',
          name: '标记',
          width: 45,
          formatter: ({ row }) => {
            return row.platformDelete ? (
              <div className={styles.tagsWrapper}>
                <div className={styles.deleteTag}>
                  删
                </div>
              </div>
            ) : null;
          },
        },
        {
          key: 'picUrl',
          name: '图片',
          width: 45,
          formatter: ({ row }) => {
            return (
              <div className={styles.alignCenter}>
                {
                  row.picUrl ? (
                    <ImgFormatter
                      height={40}
                      value={row.picUrl}
                      width={40}
                    />
                  ) : (
                    <img
                      className={styles.goodsPic}
                      src={noPic}
                    />
                  )
                }
              </div>
            );
          },
        },
        {
          key: 'color',
          name: '颜色',
          width: 45,
        },
        {
          key: 'size',
          name: '尺码',
          width: 45,
        },
        {
          key: 'platformSkuId',
          name: '平台SKUID',
          width: 90,
        },
        {
          key: 'gmsGoodsSkuNo',
          name: 'SKU编码',
          width: 150,
        },
        {
          key: 'posGoodsSkuId',
          name: '',
          width: 45,
          formatter: ({ row }) => {
            return row.posGoodsSkuId && Number(row.posGoodsSkuId) > 0 ? (
              <Button
                onClick={() => this.cancelSkuMatch(row.gmsGoodsSkuId)}
                type="link"
              >
                取消关联
              </Button>
            ) : (
              <Button
                onClick={() => this.singleMatchModalStore.onShow(this.gmsGoodsId, row.gmsGoodsSkuId)}
                type="link"
              >
                关联SKU
              </Button>
            );
          },
        },
        {
          key: 'costPrice',
          name: '关联图片',
          width: 45,
          formatter: ({ row }) => {
            return row.posGoodsSkuPicUrl ? (
              <div className={styles.alignCenter}>
                <ImgFormatter
                  height={40}
                  value={row.posGoodsSkuPicUrl}
                  width={40}
                />
              </div>
            ) : (
              <div>
                - -
              </div>
            );
          },
        },
        {
          key: 'posGoodsNo',
          name: '关联货号',
          with: 80,
          formatter: ({ row }) => {
            return row.posGoodsNo || '- -';
          },
        },
        {
          key: 'posGoodsSkuColor',
          name: '关联颜色',
          width: 45,
          formatter: ({ row }) => {
            return row.posGoodsSkuColor || '- -';
          },
        },
        {
          key: 'posGoodsSkuSize',
          name: '关联尺码',
          width: 45,
          formatter: ({ row }) => {
            return row.posGoodsSkuSize || '- -';
          },
        },
        {
          key: 'posGoodsSkuNo',
          name: '关联SKU编码',
          width: 172,
          formatter: ({ row }) => {
            return row.posGoodsSkuNo || '- -';
          },
        },

      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'gmsGoodsSkuId',
      sortByLocal: false,
      showCheckBox: false,
      showPager: false,
      showGridOrderNo: false,
      showEmpty: true,
      rowHeight: 50,
    });
}
