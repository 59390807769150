import { Card, Layout } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import styles from './index.less';
import { SkuMatchDetails } from './skuMatchDetails/index';
import StoreGoodsManageStore from './store';

const StoreGoodsManagement = observer(() => {
  const store = useMemo(() => new StoreGoodsManageStore(), []);
  const { normalProgramme, mainSubStructureModel, skuMatchDetailsStore } = store;

  useEffect(() => {
    mainSubStructureModel.gridModel.onQuery();
  }, []);

  return (
    <Layout className={styles.container}>
      <Layout.Content>
        <Card>
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div className={styles.tableWrapper}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Layout.Content>
      <SkuMatchDetails store={skuMatchDetailsStore}/>
    </Layout>
  );
});

export default StoreGoodsManagement;
