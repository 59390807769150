import { Button, message, Modal, Tooltip } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { ImgFormatter, MainSubStructureModel, NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { api, getPlatformTypeIcon } from '../../utils';
import styles from './index.less';
import { PRODUCT_LINK } from '../myStyle/constant';
import type { Goods, Shop } from './interface';
import { PosGoodsInfo } from './posGoodsInfo';
import SkuMatchDetailsStore from './skuMatchDetails/store';

export default class StoreGoodsManageStore {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.getDict();
  }

  @observable public skuMatchDetailsStore = new SkuMatchDetailsStore();

  @action public handleQuery = (params) => {
    const {
      filterParams = {},
      ...rest
    } = params;
    if (filterParams.platformId && filterParams.skuDelistingFlag) {
      message.error('店铺商品ID和订单标记SKU下架查询条件不可同时填写');
      return Promise.reject();
    }
    if (filterParams.skuDelistingFlag) {
      filterParams.skuDelistingFlag = filterParams.skuDelistingFlag === '1';
    }
    if (filterParams.createTime) {
      const time = filterParams.createTime.split(',');
      filterParams.startCreateTime = time[0] || '';
      filterParams.endCreateTime = time[1] || '';
      delete filterParams.createTime;
    }
    return request<PaginationData<Goods>>({
      url: api.getGoodsList,
      method: 'POST',
      data: {
        ...rest,
        ...filterParams,
        sourceFrom: [1], // 商品来源:0.app选款，1.平台下载,2.系统创建
      },
    });
  };

  @action private getDict = async(): Promise<void> => {
    const res: BaseData<{ shopList: Shop[]; }> = await request({
      url: api.getDict,
      method: 'POST',
      data: { commonDictList: ['shopList']},
    });
    const list = res.data?.shopList?.map((item) => {
      return {
        label: item.value,
        value: String(item.key),
      };
    });

    this.normalProgramme.filterItems.updateFilterItem([
      {
        field: 'shopId',
        data: list,
      },
    ]);
  };

  // 批量下架
  @action private batchOffShelf = () => {
    const selectedIds = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
    if (selectedIds.length === 0) {
      message.warn('请选择至少一条数据');
      return;
    }
    const selectedRows = this.mainSubStructureModel.gridModel.selectRows;
    if (selectedRows.some((item) => item.salesStatus === 2)) {
      message.warn('已下架商品不能再次下架');
      return;
    }
    Modal.confirm({
      title: `确认下架这${selectedIds.length}款商品吗？`,
      onOk: async() => {
        await request({
          url: api.delisting,
          method: 'POST',
          data: { gmsGoodsId: selectedIds },
        });
        this.mainSubStructureModel.gridModel.onRefresh();
      },
    });
  };

  // 单个上/下架
  @action private handleOffOrOnShelf = (id: number, isOff: boolean): void => {
    Modal.confirm({
      title: `确认${isOff ? '下架' : '上架'}该商品吗？`,
      onOk: async() => {
        const res = await request<BaseData<{ success: boolean; message: string; }>>({
          url: isOff ? api.delisting : api.gmsUpdateSaleStatus,
          method: 'POST',
          data: { gmsGoodsId: isOff ? [id] : id },
        });

        // 上下架返回数据结构不一样
        if (!isOff) {
          if (res.data?.success) {
            message.success('上架成功');
            isOff && this.mainSubStructureModel.gridModel.onRefresh(); // 上架为异步操作，不刷新
          } else {
            message.error(res.data?.message);
          }
        } else {
          message.success('下架成功');
          isOff && this.mainSubStructureModel.gridModel.onRefresh(); // 上架为异步操作，不刷新
        }
      },
    });
  };

  // 取消关联
  @action private cancelMatch = (gmsGoodsId: number, gotoMatch?: boolean): void => {
    Modal.confirm({
      title: '确认取消关联吗？',
      onOk: async() => {
        await request({
          url: api.gmsCancelMatch,
          method: 'POST',
          data: { gmsGoodsId },
        });
        message.success('取消关联成功');
        if (gotoMatch) {
          this.gotoMatchSku(gmsGoodsId);
        }
        this.mainSubStructureModel.gridModel.onRefresh();
      },
    });
  };

  @action public gotoMatchSku = (gmsGoodsId: number): void => {
    top.egenie.openTab(`/egenie-ts-vogue/matchGoods/index?gmsGoodsIdList=${JSON.stringify([gmsGoodsId])}&type=1`, 'matchGoods', '关联商品');
    Modal.confirm({
      title: '确认是否关联成功？',
      okText: '已关联成功',
      onOk: () => {
        this.mainSubStructureModel.gridModel.onRefresh();
      },
    });
  };

  @observable public normalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'platformCode',
        label: '店铺商品编码',
      },
      {
        type: 'select',
        field: 'shopId',
        label: '上架店铺',
      },
      {
        type: 'input',
        field: 'platformId',
        label: '店铺商品ID',
      },
      {
        type: 'select',
        field: 'matchStatus',
        label: '关联状态',
        showSearch: false,
        data: [
          {
            label: '未关联',
            value: '0',
          },
          {
            label: '已关联',
            value: '1',
          },
          {
            label: '部分关联',
            value: '2',
          },
        ],
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '创建时间',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'input',
        field: 'styleNo',
        label: '档口货号',
      },
      {
        type: 'select',
        field: 'salesStatus',
        label: '店铺在售状态',
        showSearch: false,
        data: [
          {
            label: '出售中',
            value: '1',
          },
          {
            label: '仓库中',
            value: '2',
          },
        ],
      },
      {
        type: 'select',
        field: 'vendorSalesStatus',
        label: '档口在售状态',
        showSearch: false,
        data: [
          {
            label: '出售中',
            value: '3',
          },
          {
            label: '仓库中',
            value: '4',
          },
        ],
      },
      {
        type: 'input',
        field: 'vendorShopName',
        label: '档口名称',
      },
      {
        type: 'select',
        field: 'skuDelistingFlag',
        label: '订单标记SKU下架',
        data: [
          {
            label: '是',
            value: '1',
          },
        ],
      },
    ],
    count: 6,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });;

  @observable public mainSubStructureModel = new MainSubStructureModel(
    {
      grid: {
        columns: [
          {
            key: 'operation',
            name: '操作',
            minWidth: 120,
            width: 120,
            frozen: true,
            formatter: ({ row }) => {
              return (
                <div className={styles.btns}>
                  <div
                    className={styles.operationBtn}
                    onClick={() => this.handleOffOrOnShelf(row.gmsGoodsId, row.salesStatus === 1)}
                  >
                    {row.salesStatus === 1 ? '下架' : '上架'}
                  </div>
                  {row.matchStatus !== 0 && (
                    <>
                      <div
                        className={styles.operationBtn}
                        onClick={() => this.cancelMatch(row.gmsGoodsId)}
                      >
                        取消关联
                      </div>
                      <div
                        className={styles.operationBtn}
                        onClick={() => this.cancelMatch(row.gmsGoodsId, true)}
                      >
                        重新关联
                      </div>
                      <div
                        className={styles.operationBtn}
                        onClick={() => this.skuMatchDetailsStore.onShow(row.gmsGoodsId, {
                          mainPicUrl: row.mainPicUrl,
                          goodsName: row.goodsName,
                          platformCode: row.platformCode,
                        })}
                      >
                        关联详情
                      </div>
                    </>
                  )}
                </div>
              );
            },
          },
          {
            key: 'goods',
            name: '店铺款式信息',
            minWidth: 360,

            formatter: ({ row }) => {
              return (
                <div className={styles.info}>
                  {
                    row.mainPicUrl ? (
                      <ImgFormatter
                        height={56}
                        value={row.mainPicUrl}
                        width={56}
                      />
                    ) : (
                      <img
                        className={styles.goodsPic}
                        src={noPic}
                      />
                    )
                  }
                  <div className={styles.infoRightPart}>
                    <div
                      className={`${styles.goodsName} ${styles.link}`}
                      onClick={
                        () => {
                          if (row.platformType !== 32) {
                            window.top.open(PRODUCT_LINK[row.platformType](row.platformId));
                          }
                        }
                      }
                      title={row.goodsName}
                    >
                      <span className={styles.goodsNameText}>
                        {row.goodsName}
                      </span>
                      {row.platformType !== 32 ? <i className="icon-sq"/> : null }
                    </div>
                    <div className={styles.goodsName}>
                      ID：
                      {row.platformId}
                    </div>
                    <div
                      className={styles.goodsName}
                      title={row.platformCode}
                    >
                      店铺商品编码：
                      {row.platformCode}
                    </div>
                    <div className={row.salesStatus === 1 ? `${styles.goodsStatus} ${styles.greenTags}` : `${styles.goodsStatus} ${styles.redTags}`}>
                      {row.salesStatus === 1 ? '出售中' : '仓库中'}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            key: 'createTime',
            name: '创建时间',
            minWidth: 160,
            width: 160,
          },
          {
            key: 'salePrice',
            name: '销售价',
            minWidth: 120,
            width: 120,
            formatter: ({ row }) => {
              return row.salePrice ? (
                <span>
                  &yen;
                  {row.salePrice}
                </span>
              ) : (
                <span>
                  -
                </span>
              );
            },
          },
          {
            key: 'costPrice',
            name: '拿货价',
            minWidth: 120,
            width: 120,
            formatter: ({ row }) => {
              return row.costPrice ? (
                <span>
                  &yen;
                  {row.costPrice}
                </span>
              ) : (
                <span>
                  -
                </span>
              );
            },
          },
          {
            key: 'shopName',
            name: '店铺',
            width: 200,
            minWidth: 200,
            formatter: ({ row }) => {
              return (
                <span>
                  <img
                    className={styles.shopImg}
                    src={getPlatformTypeIcon(row.platformType)}
                  />
                  {row.shopName || '-'}
                </span>
              );
            },
          },
          {
            key: 'matchStatus',
            name: (
              <Tooltip title="店铺里的商品需要和衫海精平台商品关联上，才能代发；如果非衫海精的商品可忽略。">
                <span>
                  关联衫海精商品
                  <i className={`icon-note_zs ${styles.tipsIcon}`}/>
                </span>
              </Tooltip>
            ),
            minWidth: 400,
            formatter: ({ row }) => {
              const goods = row.matchedGmsStyleList?.length > 0 ? row.matchedGmsStyleList[0] : undefined;
              return row.matchStatus === 0 ? (
                <Button
                  className={styles.matchBtn}
                  onClick={() => this.gotoMatchSku(row.gmsGoodsId)}
                  type="link"
                >
                  去关联&gt;
                </Button>
              ) : (
                <PosGoodsInfo
                  goods={goods}
                  list={row.matchedGmsStyleList}
                  showPopover
                />
              );
            },
          },

        ].map((info) => ({
          resizable: true,
          sortable: false,
          ...info,
        })),
        rows: [],
        primaryKeyField: 'gmsGoodsId',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: true,
        rowHeight: 96,
      },
      api: { onQuery: this.handleQuery },
      hiddenSubTable: true,
      buttons: [
        {
          text: '批量下架',
          handleClick: this.batchOffShelf,
        },
      ],
    });
}
