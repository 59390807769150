import { Button, Form, Input, Select } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-utils';
import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';
import { api } from '../../../utils';
import styles from './index.less';

const Option = Select.Option;

interface Vendor {
  id: number;
  shopName: string;// 档口名字
  shopNo: string;// 档口编号
}

interface Props {
  inputStyle?: CSSProperties;
  setVendorRef: (ref: FormInstance) => void;
  onSearch: () => void;
}

// 单个/批量关联sku-档口筛选组合项表单
const VendorGoodsForm = (props: Props) => {
  const {
    inputStyle,
    onSearch,
    setVendorRef,
  } = props;

  const [
    vendorList,
    setVendorList,
  ] = useState<Vendor[]>([]);

  useEffect(() => {
    onSearchVendorList();
  }, []);
  const onSearchVendorList = (text = '') => {
    request({
      url: api.getOperationShopList,
      data: {
        page: 1,
        pageSize: 200,
        shopNo: text,
      },
      method: 'POST',
    })
      .then((res: PaginationData<Vendor>) => {
        setVendorList(res.data.list);
      });
  };

  const filterOption = (input, option): boolean => {
    return (option?.children ?? '').toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div className={styles.vendorGoodsForm}>
      <Form
        layout="inline"
        ref={setVendorRef}
      >
        <Form.Item name="vendorId">
          <Select
            allowClear
            className={styles.input}
            filterOption={filterOption}
            onClear={onSearch}
            onSearch={onSearchVendorList}
            onSelect={onSearch}
            optionFilterProp="children"
            placeholder="请选择档口"
            showSearch
            style={inputStyle}
          >
            {vendorList?.map((item) => {
              return (
                <Option
                  key={item.id}
                  value={item.id}
                >
                  {item.shopNo}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="goodsName">
          <Input
            allowClear
            className={styles.input}
            onPressEnter={onSearch}
            placeholder="款式名称"
            style={inputStyle}
          />
        </Form.Item>
        <Form.Item name="goodsNo">
          <Input
            allowClear
            className={styles.input}
            onPressEnter={onSearch}
            placeholder="衫海精款式货号"
            style={inputStyle}
          />
        </Form.Item>
      </Form>
      <Button
        className={styles.queryBtn}
        onClick={onSearch}
        type="primary"
      >
        查询
      </Button>

    </div>
  );
};

export { VendorGoodsForm };
